<template>
    <div class="editPrintModuleSet">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="bnts"
                @saveClick="saveData()"
                @goBackClick="back()"/>
        </div>
        <div class="panel-box">
            <div class="panel-heading">主信息</div>
            <div class="panel-body">
                <el-row :gutter="10">
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box"><span class="red">*</span>名称：</span>
                            <div  class="input-from"><input type="text" v-model="info.MODULE_NAME" :disabled="isPublic"/></div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box"><span class="red">*</span>类型：</span>
                            <div  class="input-from">
                                <el-select v-model="info.MODULE_KIND" placeholder="请选择类型" :disabled="disabledKind">
                                    <el-option value="" label="请选择类型"></el-option>
                                    <el-option :value="1" label="消费清单"></el-option>
                                    <el-option :value="2" label="结帐清单"></el-option>
                                    <el-option :value="4" label="快餐结算单"></el-option>
                                    <el-option :value="6" label="外送结帐单"></el-option>
                                    <el-option :value="8" label="客看单"></el-option>
                                    <el-option :value="9" label="结班单"></el-option>
                                    <el-option :value="10" label="日结单"></el-option>
                                    <el-option :value="11" label="配菜单"></el-option>
                                    <el-option :value="51" label="办卡"></el-option>
                                    <el-option :value="52" label="卡充值"></el-option>
                                    <el-option :value="801" label="厨房单"></el-option>
                                    <el-option :value="810" label="整桌催菜通知单"></el-option>
                                    <el-option :value="811" label="整桌起菜通知单"></el-option>
                                    <el-option :value="812" label="整桌等叫通知单"></el-option>
                                    <el-option :value="813" label="整桌退菜通知单"></el-option>
                                    <el-option :value="820" label="退菜通知单"></el-option>
                                    <el-option :value="821" label="催菜通知单"></el-option>
                                    <el-option :value="822" label="起菜通知单"></el-option>
                                    <el-option :value="823" label="转菜通知单"></el-option>
                                    <el-option :value="830" label="换台通知单"></el-option>
                                    <el-option :value="831" label="并台通知单"></el-option>
                                    <el-option :value="832" label="改席通知单"></el-option>
                                    <el-option :value="9011" label="AR清账打印模板"></el-option>
                                    <el-option :value="9012" label="AR预付款打印模板"></el-option>
                                    <el-option :value="903" label="押金单"></el-option>
                                    <el-option :value="53" label="退卡单"></el-option>
                                    <el-option :value="904" label="核销券打印模版"></el-option>
                                    <el-option :value="905" label="暂存单打印模版"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">备注：</span>
                            <div  class="input-from"><input type="text" v-model="info.REMARK" :disabled="isPublic"/></div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="left-table table-box">
                    <el-table style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                     :data="info.PosPrintModuleConsumeList"
                     highlight-current-row
                     @current-change="(row)=>writeRow=row"
                    >
                        <el-table-column fixed type="index" label="序号" width="40" />
                        <el-table-column fixed label="操作" width="70" v-if="!isPublic">
                            <template #default="scope">
                                <div class="operation">
                                    <b class="iconfont icon-jia" @click="add(scope.$index)"></b>
                                    <b class="iconfont icon-jian" @click="del(scope.$index)"></b>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="PRINT_TEXT" label="内容" min-width="150">
                            <template #default="scope" >
                                <div class="input-from">
                                    <input type="text" v-model="scope.row.PRINT_TEXT" :disabled="isPublic" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="PRINT_LENGTH" label="参数长度以/分割" min-width="150">
                            <template #default="scope" >
                                <div class="input-from" >
                                    <input type="text" v-model="scope.row.PRINT_LENGTH" :disabled="isPublic"/>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="字体" min-width="100">
                            <template #default="scope">
                                <el-select v-model="scope.row.FONT_NAME" :disabled="isPublic" >
                                    <el-option value="宋体" label="宋体"></el-option>
                                    <el-option value="黑体" label="黑体"></el-option>
                                    <el-option value="仿宋" label="仿宋"></el-option>
                                    <el-option value="微软雅黑" label="微软雅黑"></el-option>
                                    <el-option value="华文楷体" label="华文楷体"></el-option>
                                    <el-option value="华文隶书" label="华文隶书"></el-option>
                                    <el-option value="华文宋体" label="华文宋体"></el-option>
                                    <el-option value="华文细黑" label="华文细黑"></el-option>
                                    <el-option value="华文新魏" label="华文新魏"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="字号" width="120">
                            <template #default="scope">
                                <el-select v-model="scope.row.FONT_SIZE" :disabled="isPublic" >
                                    <el-option value="10" label="10"></el-option>
                                    <el-option value="12" label="12"></el-option>
                                    <el-option value="14" label="14"></el-option>
                                    <el-option value="16" label="16"></el-option>
                                    <el-option value="18" label="18"></el-option>
                                    <el-option value="20" label="20"></el-option>
                                    <el-option value="30" label="30"></el-option>
                                    <el-option value="40" label="40"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="是否粗体" width="80">
                            <template #default="scope">
                                 <el-checkbox v-model="scope.row.FONT_BOLD" :label="false" size="large" :disabled="isPublic"></el-checkbox>
                            </template>
                           
                        </el-table-column>
                        <el-table-column prop="" label="空则不打印" width="80">
                            <template #default="scope">
                                 <el-checkbox v-model="scope.row.PRINT_EMPTH" :label="false" size="large" :disabled="isPublic"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="对齐" width="100">
                            <template #default="scope">
                                <el-select v-model="scope.row.INTALIGN" :disabled="isPublic" >
                                    <el-option :value="0" label="居左"></el-option>
                                    <el-option :value="1" label="居中"></el-option>
                                    <el-option :value="2" label="居右"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="right-table table-box" >
                    <el-table style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                     :data="tableData"
                     highlight-current-row
                     @row-dblclick="dblclick"
                    >
                        <el-table-column fixed type="index" label="序号" width="40" />
                        <el-table-column prop="NOTE_NAME" label="打印内容" width="150"></el-table-column>
                        <el-table-column prop="REMARK" label="内容说明" width="150"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'editPrintModuleSet',
    data(){
        return {
            info:{
                MODULE_CODE:'',
                MODULE_NAME:'',
                MODULE_KIND:'',
                REMARK:'',
                PosPrintModuleConsumeList:[{PRINT_TEXT:"",PRINT_LENGTH:'',FONT_NAME:'宋体',FONT_SIZE:10,FONT_BOLD:false,PRINT_EMPTH:false,INTALIGN:0}]
            },
            //打印内容字典
            PosPrintModuleNodes:[],
            writeRow:null,
            isPublic:false//是否共有模板
        }
    },
    watch:{
    },
    computed:{
        bnts(){
            let data=["goBack"];
            if(!this.isPublic){
                data.push("save")
            }
            return data;
        },
        tableData(){
            let kind=0;
            switch (this.info.MODULE_KIND) {
                case 1:
                case 2:
                case 4:
                case 6: kind = 1; break;
                case 8: kind = 3; break;
                case 9: kind = 4; break;
                case 10: kind = 4; break;
                case 11: kind = 3; break;
                case 51: kind = 51; break;
                case 52: kind = 51; break;
                case 53: kind = 51; break;
                case 801: kind = 801; break;
                case 810: kind = 801; break;
                case 811: kind = 801; break;
                case 812: kind = 801; break;
                case 813: kind = 801; break;
                case 820: kind = 801; break;
                case 821: kind = 801; break;
                case 822: kind = 801; break;
                case 823: kind = 801; break;
                case 830: kind = 801; break;
                case 831: kind = 801; break;
                case 832: kind = 801; break;
                case 9011: kind = 901; break;
                case 9012: kind = 901; break;
                case 903: kind = 903; break;
                case 904: kind = 9041; break;
                case 905: kind = 9051; break;
            }
            if(kind>0){
                return this.PosPrintModuleNodes.filter(t=>t.KIND==kind);
            }
            return [];
        },
        /**是否禁用模版类型 */
        disabledKind(){
            if(this.info?.MODULE_AUTOID){
                return true;
            }
            return false;
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadPosPrintModuleNote();
        if(this.$route.params){
            this.isPublic=this.$route.params.ISPUBLIC=="true";
            this.loadData(this.$route.params.id,this.$route.params.isAdd=="true");
        }
    },
    methods:{
        back(){
            this.$router.push("printModuleSet");
        },
        //获取打印内容字典
        loadPosPrintModuleNote(){
            const loading = this.$loading({
                text: "加载打印内容字典中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPosPrintModuleNoteList",{ }).then((d)=>{
                loading.close();
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    this.PosPrintModuleNodes=d.ResponseBody||[];
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('未找到打印内容字典数据：'+e);
                console.log('未找到打印内容字典数据：'+e);
            })
        },
        loadData(id,isAdd){
            if(!id) return;
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPosPrintModuleInfo",{
                MODULE_AUTOID:id
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.info=d.ResponseBody||{};
                    if(isAdd){
                        this.info.MODULE_AUTOID="";
                    }
                    if(this.info.PosPrintModuleConsumeList?.length>0){
                        this.info.PosPrintModuleConsumeList.forEach((it)=>{
                            if(it.INTALIGN==undefined || it.INTALIGN==null){
                                it.INTALIGN=0;
                            }
                        })
                    }else{
                        this.info.PosPrintModuleConsumeList=[{PRINT_TEXT:"",PRINT_LENGTH:'',FONT_NAME:'宋体',FONT_SIZE:10,FONT_BOLD:false,PRINT_EMPTH:false,INTALIGN:0}];
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    setTimeout(()=>{this.$router.back()},500);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('未找到对应模板数据：'+e);
                console.log('未找到对应模板数据：'+e);
                setTimeout(()=>{this.$router.back()},500);
            })
        },
        add(index){
            this.info.PosPrintModuleConsumeList.splice(index+1,0,{PRINT_TEXT:"",FONT_NAME:'宋体',FONT_SIZE:10,FONT_BOLD:false,PRINT_EMPTH:false,INTALIGN:0});
        },
        del(index){
            this.info.PosPrintModuleConsumeList.splice(index,1);
            if(this.info.PosPrintModuleConsumeList.length==0){
                this.add(0)
            }
        },
        /**保存数据 */
        saveData(){
            let info={
                MODULE_AUTOID:this.info.MODULE_AUTOID,
                MODULE_NAME:this.info.MODULE_NAME.trim(),
                MODULE_KIND:this.info.MODULE_KIND,
                REMARK:this.info.REMARK.trim()
            }
            if(!info.MODULE_NAME){
                this.$message.warning('请输入名称！');
                return;
            }
            if(!(info.MODULE_KIND>0)){
                this.$message.warning('请选择类型！');
                return;
            }
            if(this.tableData?.length>0){
                let moduleConsume=[];
                this.info?.PosPrintModuleConsumeList.forEach((consume,index)=>{
                    moduleConsume.push({
                        ORDER_INDEX: index,//按表格序号排序
                        PRINT_TEXT: consume.PRINT_TEXT||"",
                        FONT_NAME: consume.FONT_NAME, //字体
                        FONT_SIZE: consume.FONT_SIZE, //大小
                        FONT_BOLD: consume.FONT_BOLD===true, //加粗
                        PRINT_INDEX: consume.PRINT_INDEX, //字典表排序字段
                        PRINT_LENGTH: consume.PRINT_LENGTH||"",
                        PRINT_EMPTH: consume.PRINT_EMPTH===true, //是否为空
                        INTALIGN:consume.INTALIGN
                    });
                })
                info.PosPrintModuleConsumeList=moduleConsume;
            }else{
                this.$message.warning('明细数据不能为空！');
                return;
            }
            

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.SavePosPrintModuleInfo",{
                PosPrintModuleInfo:info,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    if(d.ResponseBody){
                        this.info.MODULE_AUTOID=d.ResponseBody.MODULE_AUTOID;
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('模板数据保存失败：'+e);
                console.log('模板数据保存失败：'+e);
            })
        },
        /**双击 某一行*/
        dblclick(row){
            //共有模板不能操作
            if(this.isPublic) {return;}
            if(this.writeRow){
                this.writeRow.PRINT_TEXT=(this.writeRow.PRINT_TEXT||"")+row.NOTE_NAME;
            }else{
                this.$message.warning('请先选中左侧表格行!');
            }
        },
    }
}
</script>

<style lang="scss">
@import './editPrintModuleSet.scss'
</style>